.chatSmile {
  position: absolute;
  height: 250px;
  top: -425px;
  right: 40px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
}

.chatSmileWrapper {
  display: flex;
}
