.header {
  background-color: #fff;
  height: 70px;
  border-bottom: solid 1px #ddd;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.headerProject {

}

.logo {
  background-image: url(./imgs/lialogo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 26px;
  width: 61px;
  padding: 5px;
}

.headerDropdownProfile {
  position: relative;
  margin-left: 32px;
}

.headerProfileIcon {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background: url("https://image.flaticon.com/icons/svg/18/18601.svg") center/contain no-repeat;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding-top: 2px;

  &--bg-1 {
    background: #116FFC;
  }
  &--bg-2 {
    background: #116FFC;
  }
  &--bg-3 {
    background: #B893FD;
  }
  &--bg-4 {
    background: #E0536F;
  }
  &--bg-5 {
    background: #5CD4FF;
  }
  &--bg-6 {
    background: #5C6DFF;
  }
  &--bg-7 {
    background: #0096FF;
  }
  &--bg-8 {
    background: #116FFC;
  }
}

.headerSpacer {
  flex: 1;
}

.headerControls {
  display: inline-flex;
  align-items: center;
}

.headerControlsDivider {
  height: 25px;
  width: 1px;
  background-color: #D8D8D8;
  margin: 0 21px 0 28px;
}

.headerControlsChats {
  border: none;
  background-color: transparent;
}

.headerProfileMenu {
  width: 348px;
  top: 15px !important;
  transition: opacity .2s ease-out !important;
}

.headerProfileMenuItem {
  font-weight: 500 !important;
}

.headerProfileMenuInvite {
  font-weight: bold !important;
}

.headerMinimal {
  border-bottom: none;
  background-color: transparent;
}

.headerProfileAvatar {
  margin-left: 21px;
}
