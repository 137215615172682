.chatMessageCardWrapper {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 6px 6px 0;
    overflow: hidden;
}

.chatMessageCardText {
    padding-left: 10px;
}

.chatMessageCardTitle {
    margin-bottom: 6px;
}

.chatMessageCardSubtitle {
    color: #999;
    margin-bottom: 6px;
}

.chatMessageCardImage {
    border-radius: 10px;
    overflow: hidden;
}

.chatMessageGroup {
    display: flex;
    flex-direction: column;
    margin: 0 -7px;
}

.chatMessageButton {
    border: none;
    border-top: 1px solid #E5E5E5;
    padding: 14px 20px;
    color: #02a7e3;
    font-size: 14px;
    width: 100%;
    transition: color 0.3s ease-out;
    cursor: pointer;
    &:hover {
        color: rgba(#02a7e3, 0.7);
    }
}
