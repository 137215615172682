.rightMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rightMenuHideContent {
}

.rightMenuHideBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9px;
  background-color: #fff;
  border: none;
  z-index: 1000;

  &::before {
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    background-color: #EBEDEF;
  }
}

.rightMenu:not(.rightMenuHideContent) .rightMenuHideBtnArrow {
  transform: rotate(180deg);
}

.rightMenuHideBtnArrow {
  position: relative;
  top: 5px;
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #116FFC;
  border-radius: 50%;
  transition: transform .2s .4s ease-out;
}

.rightMenuContent {
  flex: 2;
  background-color: #fff;
  overflow: auto;
  transition: max-height .4s ease-out;
}

.rightMenuHideContent .rightMenuContent {
  max-height: 0 !important;
  overflow: hidden;
}

.rightMenuChat {
  flex: 1;
  min-height: 300px;
}
