.sidebar {
  box-shadow: none !important;
}

.menuItemIsActive {

}

.sidebarMenuWrapper {
  display: flex;
  height: 100%;
  flex: 1;
}

.customSideMenu {
  position: relative;
  width: 200px;

  &:empty {
    display: none;
  }
}

.docsLink {
  p {
    color: #b893fd !important;
    font-weight: bold;
  }
}

.sidebarMenuLeftWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 70px;
  height: 100%;
  overflow-y: auto;
  background-color: #FAFBFE;
}

.sidebarMenuDivider {
  height: 1px;
  margin: 0 2px;
  background-color: #D8D8D8;
}

.tooltip {
  position: fixed !important;
  background: #116FFC!important;
  padding: 5px 14px!important;
  color: #fff!important;
  font-weight: 600!important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1)!important;
  border-bottom-right-radius: 5px!important;
  border-top-right-radius: 5px!important;
  opacity: 1 !important;
  z-index: 100;
  &.show {
    opacity: 1 !important;
  }
  &:after {
    border-right-color: initial !important;
    border-right-style: initial !important;
    border-right-width: initial !important;
    content: ' ';
    position: absolute !important;
    left: -9px !important;
    top: -3px !important;
    bottom: 0 !important;
    background-image: url(./menu-icons/nos.svg) !important;
    background-size: contain !important;
    background-position: center !important;
    width: 14px !important;
    background-repeat: no-repeat !important;
    height: 35px !important;
  }
}

.sidebar__icon {
  color: #788D99;
  opacity: 1;
  transition: opacity .3s ease-out;
  &:hover {
    opacity: .8;
  }
}
