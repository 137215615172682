.sidebarGroupDropActive {
	transform: scale(1.2) translateX(10px);
}

.sidebarGroupWrapper {
	font-weight: 500;
	padding: 10px 0;
	transition: transform 0.3s ease-out;
	display: inline-flex;
	align-items: center;

	span {
		font-size: 13px;
		word-break: break-word;
		width: 110px;
	}

	&:hover {
		& .editGroup {
			opacity: 1;
		}

		& .removeGroup {
			opacity: 1;
		}
	}
}

.editGroup {
	transition: opacity 0.3s ease-out;
	opacity: 0;
	display: inline-block !important;
	margin-left: 5px;
	margin-bottom: 2px;
}

.removeGroup {
	transition: opacity 0.3s ease-out;
	opacity: 0;
	margin-left: 5px;
}
