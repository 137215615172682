.formField {
  display: flex;
  align-items: center;
}

.formFieldLabel {
  min-width: 180px;
  font-size: 15px;
  color: #444;
}

.formFieldSmallDropdown {
  width: 150px;
  margin-right: 10px;
}

.formFieldSmallLeftInput {
  width: 150px;
  margin-right: 10px;
}

.formFieldTimeout {
  max-width: 100px;
}

.addInput {
  color: #116FFC;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  margin-left: 180px;
}

.formCloseIcon {
  margin-left: 30px;
  min-width: 18px;
  min-height: 18px;
  cursor: pointer;
}

.checkboxItem {
  margin-bottom: 20px;
}

.checkBoxDesc {
  margin-top: -20px;
}

.timeoutDesc {
  color: #116FFC;
  margin-top: -15px;
  margin-left: 196px;
  font-size: 10px;
  display: block;
}

.urlInput {
  margin-left: 10px;
}
