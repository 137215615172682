@import "@/common/scss/variables.scss";

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-y: hidden;
}

.editorWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.shadow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
  pointer-events: none;
}

.editor {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.editorContent {
  height: 100%;
  overflow-x: auto;
}

.padding {
  padding: 30px 40px 0;
}

.actionMenu {
  height: 60px;
  box-sizing: border-box;
  border-left: 1px solid $border-color;
  border-top: 1px solid $border-color;
}

.rightBar {
  width: 383px;
  min-width: 383px;
  box-sizing: border-box;
  border-left: 1px solid $border-color;
}

@media screen and (max-width: 1480px) {
  .editorWrapper {
    width: 62%;
  }
}
