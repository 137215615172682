.filterWrapper {
  padding: 15px;
  height: 100%;
}

.subtitle {
  color: #999999;
  margin-left: 15px;
}

.wrapper {
  padding: 20px;
}

.menuTitle {
  font-size: 14px;
  margin-bottom: 20px;
}

.search {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxChecked {
  color: #116FFC !important;
}

.checkboxCmp {
  padding: 0 !important;
}

.formFieldLog {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  //:global(span.trigger) {
  //  display: block;
  //  width: 100%;
  //}
}

.searchBtn {
  font-weight: 500 !important;
}

.cancelBtn {
  color: #444 !important;
}

.failsCheckbox {
  border: 1px solid #ced4da;
  border-radius: 6px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 7px 7px 7px 10px;
  color: #444;
  transition: all 0.2s;
  &:hover {
    border: 1px solid #4096ff;
  }
}
