@import "@/common/theme/settings/variable.scss";

.sidebarRightDragIcon {
  min-width: 20px;
}

.sidebarDropActive {
  transition: transform 0.3s ease-out;
  transform: scale(1.2) translateX(10px);
}

.sidebarNotActive {
  & p {
    text-decoration: line-through;
  }
}

.sidebarRightLink {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  padding: 11px 17px;
  background: transparent;
  border: none;
  overflow: hidden;

  p {
    transition: left, color 0.3s;
  }

  &:hover {
    text-decoration: none;
    @include themify($themes) {
      background-color: themed('colorHover');
    }

    & :global(.sidebar__link-title) {
      color: $color-blue;
    }

    &:before {
      opacity: 1;
    }
  }
}

.sidebarSubMenuLinkWrapper {
  display: flex;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: $color-blue;
    opacity: 0;
    transition: all 0.3s;
  }
}

.sidebarRightLinkSubtitle {
  color: #999999;
  font-size: 10px;
}

.sidebarRightLinkCount {
  color: #999999;
  font-size: 14px;
  position: absolute;
  right: 17px;
}

.alertCount {
  color: $color-red;
}

.sidebarRightLinkTitle.sidebarRightLinkTitle {
  font-size: 13px;
  max-width: 90%;
  word-break: break-word;
}

.sidebarRightLinkArrow.sidebarRightLinkArrow {
  position: absolute;
  right: 12px;
  color: $color-gray;
  width: 14px;
  transition: all 0.3s;
  opacity: 1;
}

.sidebarRightLinkCount + .sidebarRightLinkArrow {
  right: 2px;
}

.sidebarRightLinkSelected {

  .sidebarRightLinkTitle {
    color: $color-blue;
    font-weight: 500;
  }

  .sidebarRightLinkArrow.sidebarRightLinkArrow {
    opacity: 0;
  }

  &::after {
    opacity: 1;
  }
}
