@import "@/common/scss/variables.scss";

.element {
  font-weight: normal;
  cursor: pointer;

  font-size: 14px;
  line-height: 19px;
  padding: 4px 0;
  position: relative;
  padding-left: 14px;
}

.selected {
  background: rgba(#116FFC, 0.1);
}

.type {
  margin-left: 3px;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 0.75em;
  color: $blue-color;
}

.qa {
  text-transform: uppercase;
  color: $primary-color
}

.checkbox {
  position: absolute;
  right: 15px;
  top: 9px;
}

.selectedIntent {
  color: #116FFC;
}

.selectedQA {
  color: #0AD2C9;
}
