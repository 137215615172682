@import "@/common/scss/variables.scss";

$chat-cloud-color: #D1F1FD;

.message {
  max-width: 60%;
  padding: 10px;
  font-size: 14px;
  color: $primary-text-color;
  border-radius: 5px;
  box-shadow: 0 0.5px 1px 0 rgba(0,0,0,0.13);
  margin-bottom: 15px;


  align-self: flex-end;
  background-color: #D1F1FD;
}

.outgoing {
  align-self: flex-start;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid $border-color;
}

.time {
  display: block;
  text-align: end;
  font-size: 12px;
  color: $gray-color;
  line-height: 19px;
}

.imageWrapper {
  margin-top: 15px;
}

.unanswered {
  margin-left: 5px;
  color: $red-color;
  font-size: 12px;
}

.text {
  max-width: 100%;
  overflow: hidden;
  word-break: break-word;
}


.marked {
  color: #116FFC;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.addedTo {
  margin-right: 10px;
}
