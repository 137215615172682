.quickReply {
    background-color: #D0F1FD;
    color: #000;
    border-radius: 20px;
    padding: 10px 16px;
    cursor: pointer;
    max-width: 70%;
    border: none;
}

.quickReplyItem {
    &:not(:last-child) {
        margin-right: 7px;
    }
    margin-bottom: 7px;
}

.quickReplyList {
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
