@import "@/common/scss/variables";

.modal.modal {
  max-width: 772px;
  width: 90vw;
  padding: 30px 0;
}

.modalContent.modalContent {
  text-align: left;
  padding: 0;
  font-size: 14px;
}

.modalBackdrop.modalBackdrop.modalBackdrop {
  background-color: rgba(57, 61, 65, 0.93) !important;
}

.switch.switch {
  margin: 0;
}

.blockTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
}

.generalInfo,
.projectPermissions {
  padding: 36px;
  border-bottom: 1px solid $border-color;
}

.generalInfo {

  .blockTitle {
    margin-bottom: 24px;
  }
}

.projectPermissions {

  .blockTitle {
    flex-direction: column;
    align-items: flex-start;
  }
}

.blockSubTitle {
  font-size: 14px;
  color: $gray-color;
}

.blockLine {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.blockLineSpace {
  justify-content: space-between;
}

.blockInput {
  flex-basis: 29%;
}

.blockInputEmail {
  flex-basis: 38%;
}

.rolesInfo {
  margin-left: 24px;
  line-height: 1.2;
}

.controlsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 26px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project {
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  flex-basis: calc(100% / 3 - 40px / 3);
  margin: 0 0 20px !important;
  padding-left: 10px;
  justify-content: space-between;
}

.controls {
  padding: 24px 36px;
  display: flex;
  align-items: center;
}

.cancelBtn.cancelBtn {
  border: none;
  background-color: transparent !important;
}

.error {
  flex: 1;
  color: $red-color;
}

.submitBtn {
  position: relative;
}

.submitBtnLoader {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}
