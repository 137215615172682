.send {
  flex: 2;
}

.cancel {
  flex: 1;
}

.title {
  text-transform: none !important;
}

.subtitle {
  margin-top: 18px;
  color: #999999 !important;
}
