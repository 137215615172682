@import "@/common/theme/settings/variable.scss";
@import "@/common/scss/variables.scss";

.sidebarRightWrapper {
    width: 200px;
    display: flex;
    flex-direction: column;

    &:empty {
        display: none;
    }
}



.sidebarRightLink {
    height: 36px;
    width: 100%;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 11px 20px;
    overflow: hidden;
    background: transparent;
    border: none;

    p {
        position: absolute;
        left: 20px;
        transition: left, color 0.3s;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
    }

    &:hover {
        text-decoration: none;
        @include themify($themes) {
            background-color: themed('colorHover');
        }

        & p {
            color: $color-blue;
        }

        &:before {
            opacity: 1;
        }
    }
}


.addGroupPlus {
    margin-right: 10px;
}

.addGroup {
    height: 40px;
    padding: 0 17px;
    background: #fff;
    color: #116FFC;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color .3s ease-out;
    border-bottom: 1px solid $border-color;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .10);

    &:hover {
        color: rgba($color-blue, 0.7);
    }
}

.sidebarShareItem {
    color: red!important;
    &:hover {
        color: inherit!important;
        background: transparent!important;
    }
}

.sidebarList {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.editGroup {
    margin-left: 10px;
    margin-bottom: 2px;
    display: inline-block!important;
}

.bottomMenu {
    flex: 1;
    padding: 0 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    border-top: 1px solid $border-color;
}

.bottomButton {
    padding: 10px;
    color: $color-blue;
    font-size: 14px;
    background-color: transparent;
    border: none;
}
