@import "@/common/scss/variables.scss";

.formGroupTitle {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.resetPassword {
  color: $color-blue;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  &:hover {
    opacity: 0.7;
  }
}
