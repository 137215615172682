@import "@/common/scss/variables";

.title.title {
  text-transform: none;
}

.subtitle {
  margin-top: 18px;
  color: $gray-color;
}

.formInputWrapper {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.controls.controls {
  margin-top: 24px;
}

.cardBody.cardBody {
  width: 520px;
}

.cancel.cancel {
  color: $blue-color;
}

.inputErrors {
  color: $red-color;
  position: absolute;
  top: calc(100% + 2px);
  left: 10px;
  font-size: 13px;
}
