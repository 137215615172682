@import "@/common/scss/variables.scss";
@import "@/common/scss/mixins.scss";

.editableTitle {
    @include editableTitle;
}

.link,
.link:hover,
.link:visited {
    color: #116FFC;
    text-decoration: none;
    margin-right: 12px;
}

.link:hover {
    color: darken(#116FFC, 20%);
}

.snippetArea {
    display: flex;
}

.snippetList {
    display: flex;
    flex-wrap: wrap;
}

.snippet.snippet {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
}

.help {
    color: $secondary-color !important;
    border-color: $secondary-color !important;
}

.titleEditorWrapper {
    margin-bottom: 30px;
}

.draggableElement {
    height: 42px;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
}

.dragStart {
    //visibility: hidden;
}

.snippetTitle {
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 19px;
}

.snippetSubtitle {
    color: #999999;
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 20px;
}

.snippetWrapper {
    padding: 15px;
    background: #fff;
}

.aceEditor {
    width: initial !important;
    min-width: 100%;
    height: calc(100vh - 215px) !important;
    transition: height 0.3s ease-in-out;
}

.aceEditorExecutionOpened {
    height: calc(100vh - 415px) !important;
}

.page {
    overflow: hidden;
}

.executor {
    position: relative;
    background: #fff;
    height: 208px;
    margin-left: -39px;
    margin-right: -40px;
    width: initial !important;
    min-width: 100%;
    border: 1px solid rgba(#000, 0.1);
    padding: 19px 14px;
    padding-right: 45px;
    overflow-y: auto;
    line-break: anywhere;
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
    transition: transform 0.3s ease-in-out;
    transform-origin: bottom;
    transform: scaleY(1);
    top: -191px;
}

.editorWrapper {
    margin-left: -39px;
    margin-right: -40px;
}

.executorHide {
    transform: scaleY(0)
}

.closeIcon {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #999;
    cursor: pointer;
}

.executorItem {
    display: flex;
    margin-bottom: 10px;
}

.executionSuccessIcon {
    color: #116FFC;
}

.executionIcon {
    //margin-top: 3px;
    min-width: 18px;
    max-width: 18px;
}

.loadingExecution {
    color: rgba(#000, 0.2)
}

.executionResult {
    margin-left: 35px;
    font-size: 12px;
}

.runBtn {
    margin-right: 20px!important;
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.executionEventFact {
    margin-left: 20px;
}

.header {
    @include header;
}
