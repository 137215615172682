@import "@/common/scss/variables";
@import "@/common/scss/mixins";

.user {
  @include card;
  padding: 17px;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}

.userInfo {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  flex-basis: 260px;
}

.userInfoAvatar {
  width: 35px;
  height: 35px;
  margin-right: 20px;
  border-radius: 50%;
  box-sizing: content-box;

  &--bg-1 {
    background: #116FFC;
  }
  &--bg-2 {
    background: #116FFC;
  }
  &--bg-3 {
    background: #B893FD;
  }
  &--bg-4 {
    background: #E0536F;
  }
  &--bg-5 {
    background: #5CD4FF;
  }
  &--bg-6 {
    background: #5C6DFF;
  }
  &--bg-7 {
    background: #0096FF;
  }
  &--bg-8 {
    background: #116FFC;
  }
}

.userInfoDefault {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}

.userInfoAvatarImg {
  width: 100%;
  height: auto;
}

.userInfoContent {
  line-height: 1.3;
}

.userInfoName {
  font-weight: 500;
}

.userInfoEmail {
  color: $gray-color
}

.userControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 410px;
}

.userStatus {
  margin-right: 20px;
  text-align: right;
}

.userProjects {
  text-align: center;
  flex-basis: 20%;
}
