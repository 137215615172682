.node {
  position: relative;
  width: 100%;
  min-height: 60px;
  height: 100%;
  background: #116FFC;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  border-radius: 5px;
}

.node:hover {
  .delete {
    display: block;
  }
}

.delete {
  display: none;
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}
