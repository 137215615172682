.sidebarSearchInput {
  //width: 100%;
  //border: none;
  padding: 8px 10px 7px 5px;
  //outline: none;
  //font-size: 12px;
  //color: #999;
  //background-color: inherit;
  //
  //&::placeholder {
  //  color: #999;
  //}
}

.sidebarSearchWrapper {
  display: flex;
  align-items: center;
  margin: 0 9px;
  background-color: #F2F4F7;
  min-height: 28px;
}

.sidebarSearchWrapperDisabled {
  opacity: 0.6;
  pointer-events: none;
}


.sidebarSearchIcon {
  color: #999;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.loader {
  color: #999;
  height: 20px;
  width: 20px;
  margin-bottom: 4px;
  margin-left: 6px;
  margin-right: 4px;
  transform: scale(0.4);

  & svg path {
    fill: #999 !important;
  }
}
