.formGroupDropdown {
  width: 100%;
  & :global(.dropdown-menu) {
    width: 100%;
  }

  & :global(.dropdown-item) {
    width: 100%;
  }

  & :global(.btn) {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    position: relative;
    & svg {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -12px;
      fill: #495057 !important;
    }

    border: 1px solid #ced4da !important;
    background: #fff;
    width: 100%;

    p {
      color: #495057!important;
      &:hover {
        color: #495057!important;
      }
    }
    &:hover {
      color: #495057;
      background: #fff;
    }
  }
}

.formGroup {
  display: flex;
  align-items: center;
}

.formField {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.formFieldLabel {
  min-width: 180px;
  font-size: 15px;
  color: #444;
}

.formFieldLeftEquals {
  margin-right: 10px;
}

.formElementsGroupWrapper {
  & .formGroup {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.formGroupLabel {
  min-width: 180px;
  font-size: 15px;
  color: #444;
}

.formGroupTitle {
  font-size: 24px;
  margin-bottom: 38px;
  font-weight: bold;
}

.link,
.link:hover,
.link:visited {
  color: #116FFC;
  text-decoration: none;
  margin-right: 12px;
}

.link:hover {
  color: darken(#116FFC, 20%);
}

.formFieldStart {
  align-items: flex-start;
  & .formFieldLabel {
    margin-top: 10px;
  }
}

.inputSmile {
  height: 32px !important;
  font-size: 12px !important;
  background-color: #fff !important;

  &:focus {
    border-color: #ced4da !important;
    box-shadow: none !important;
  }
}

.inputFilter {
  height: 40px !important;
  font-size: 14px !important;
  background-color: #fff !important;
  color: #444 !important;

  &:focus {
    border-color: #ced4da !important;
    box-shadow: none !important;
  }
}

.datePicker {
  :global .react-datepicker__input-container input {
    font-size: 14px !important;
    height: 40px !important;
  }
}

.dropdownSmall {
  width: 100%;
  & :global(.dropdown-menu) {
    max-height: 35vh;
    overflow-y: auto;
    overflow-x: visible;
    width: 100%;
    padding: 0;
  }

  & :global(.dropdown-item) {
    display: flex;
    align-items: center;

    &:active {
      background: transparent;
      color: #444;
    }

  }
}
