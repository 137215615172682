.wrapper {
  background-color: white;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.item {
  height: 100%;
  padding: 12px 0;
}
