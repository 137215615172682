.shareLocation {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.shareBtn {
  color: #206AFB;
  cursor: pointer;
}
