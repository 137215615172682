@import "@/common/scss/variables.scss";

.popper {
  width: 400px;
  z-index: 1000;
}

.list {
  overflow-y: auto;
  min-height: 100px;
  height: 250px;
}

.actionIcon {
  margin-left: 15px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  height: 60px;
  padding-top: 6px;
  border-top: 1px solid $border-color;
  padding-right: 14px;
}

.ok {
  padding: 3px 30px !important;
}

.cancel {
  padding: 3px 30px !important;
}

.tooltipContainerMarkup {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 12px !important;
}

.searchWrapper {
  padding: 0 15px;
  margin-bottom: 25px;
}

.btnLink {
  padding: 0 !important;
  display: block !important;
}

.textareaWrapper {
  display: flex;
  justify-content: center;
  margin: 24px 14px;
  border-top: 1px solid $border-color;
}

.textarea {
  width: 100%;
  border-radius: 5px;
  border-color: #C4C4C4;
  padding: 10px;
  color: #444444;
  resize: none;
}

.textareaBorder {
  margin-top: 14px;
  height: 1px;
  width: 100%;
  background: $border-color;
}
