.media {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img, video, audio {
    width: 50%;
    height: auto;
  }

  audio {
    min-height: 50px;
  }
}

.map {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
