.icon {
  display: flex;
}


.remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.input {
  height: 42px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.footer {
  margin-top: 20px !important;
}

.loadingBtn {
  background: #B893FD !important;
  border-color: #B893FD !important;
}

.errorWrapper {
  margin-top: -5px;
  margin-bottom: 10px;
}
