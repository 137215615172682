@import '@/common/scss/variables.scss';

.example {
  position: relative;
  font-size: 14px;
  color: $primary-text-color;
  line-height: 2em;
  vertical-align: center;
  margin: 8px 0;
  padding: 0 10px;

  &:hover {
    background-color: rgba($gray-color,.16);
    border-radius: 5px;

    .remove {
      visibility: visible;
    }
  }

  &::selection {
    background-color: $blue-color !important;
    color: white !important;

    span::selection {
      background-color: $blue-color !important;
      color: white !important;
    }
  }
}

.remove {
  cursor: pointer;
  visibility: hidden;
}

.actionsContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  display: flex;
  gap: 5px;
}

.exampleContainer {
  outline: none;
  user-select: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.partWrapper {
  padding-right: 40px;
}
