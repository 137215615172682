.chatWrapper {
    height: 100%;
    position: relative;
}

.chatUpdateNow {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.chatUpdateNowMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.chatUpdateNowIcon {
    margin-right: 10px;
    margin-bottom: 10px;
}
