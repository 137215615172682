.apiIconCopy {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}

.formFieldRelative {
  position: relative;
  align-items: baseline;
}

.widgetWrapper {

}

.linkToWidget {
  margin-left: 160px !important;
  margin-top: -20px !important;
}

.widgetPicker {
  position: relative;
}

.widgetPickerPanel {
  position: absolute;
}

.colorInput {
  width: 150px!important;
  margin: 0 !important;
}

.selectColor {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  margin-left: 8px;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.radioGroup {
  display: flex;
  align-items: stretch;
}

.chatClosedButtonIcon {
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #d620fa;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.9;
  }
}

.chatClosedButtonCircleIcon {
  border-radius: 50%;
}

.chatIcon {
  width: 30px;
  height: 30px;
}

.chatText {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #d620fa;
  min-height: 43px;
  width: 286px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-top: 20px;
  box-shadow: 0 4px 15px rgba(0,0,0,.1);
  flex-grow: 1;
}

.chatHeaderName {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #fff;
}

.chatHeaderIcon {
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  &:hover {
    opacity: 0.5;
  }
}

.chatTextIcon {
  width: 20px;
  height: 20px;
}

.radioItems {
  margin-right: 30px;
}

.radioBtn {
  margin-bottom: 30px;
}

.widgetText {
  margin-top: 16px;
}

.messageDesc {
  color: #555555;
  margin-bottom: 30px;
  padding-left: 180px;
  font-size: 12px;
}

.appearance {
  margin-top: 40px;
}

.formFieldInputWrapper {
  position: relative;
  flex: 1;
}

.formFieldInput {
  width: 100%;
  display: inline-flex;
  flex: 1;

  .apiIconCopy {
    position: static;
    margin-top: 7px;
    margin-left: 10px;
  }
}

.formFieldLink {
  display: inline-flex;
  margin-top: 10px;
  margin-left: 12px;
}

.formFieldLabel{
  font-size: 15px;
  color: #444;
  width: 225px;
  margin-right: 20px;
}

.lastFormGroup{
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.lastFormCheckbox{
  margin-left: 0 !important;
}


.groupRow{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.unassignCheckbox{
  margin-top: -20px;

}
