@import "@/common/scss/variables.scss";

.intent {
	min-width: 105px;
	margin-bottom: 10px;
	white-space: nowrap;
	padding: 10px 5px !important;

	&:global(.btn.btn-outline-primary) {

		&:active {
			color: $color-primary !important;
		}

		&:hover {
			opacity: .6;
		}
	}
}