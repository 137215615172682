@import "../theme/app.scss";

.link,
.link:hover,
.link:visited {
	color: #116FFC;
	text-decoration: none !important;
	margin-right: 12px !important;
}

.link:hover {
	color: darken(#116FFC, 20%);
}

input,
textarea {
	outline: none;
}

.form-control:focus {
	border-color: #ced4da !important;
	box-shadow: none;
}

.modal-backdrop.show {
	background-color: #fff !important;
	opacity: .7;
}

.react-datepicker {
	display: flex;
}

//.ant-input-suffix .anticon {
//	display: inline-flex;
//}
//
//.ant-btn.ant-btn-icon-only {
//	display: flex;
//	justify-content: center;
//	align-items: center;
//}
