.buttonGroup {
    & .button {
        border-bottom: none;
        border-radius: 0;
        &:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom: 1px solid #E5E5E5;
        }
    }
}

.button {

}
