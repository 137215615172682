.input {
  width: 64% !important;
  height: 32px !important;
  font-size: 14px!important;
  color: #999999 !important;
}

.selector {
  width: 33% !important;
}

.input, .selector {
  display: inline-block !important;
}

.editor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.editorForm {
  margin-left: 12px;
  height: 32px;
  & :global(.btn) {
    height: 32px;
    padding: .375rem .75rem;
    font-size: 14px;
    p {
      color: #999999 !important;
    }
  }

  & :global(.dropdown-menu) {
    max-height: 200px;
    overflow-y: auto;
  }
}
