@import "src/common/scss/variables";

.wrapper {
    background-color: #fff;
}

.container {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 70px);
    height: 100%;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title.title {
    margin: 25px 36px 23px;
    font-size: 24px;
    color: $primary-text-color;
}

.activityLogsFilter {
    padding: 0 36px 26px;
    border-bottom: solid 1px #D8D8D8;
}

.activityLogsItems {
    padding: 50px 36px 20px;
}

.activityLogsItem {
    border-top: solid 1px #D8D8D8;

    &:last-child {
        border-bottom: solid 1px #D8D8D8;
    }
}

.itemsTable {
    width: 100%;
}

.column {
    padding: 10px 20px;

    &:nth-child(4) {
        width: 230px;
    }
}
