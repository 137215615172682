@import "@/common/scss/variables.scss";

.logs {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.sessions {
  background: white;
  flex: 240px 0;
  overflow-y: auto;
  z-index: 10;
  border-right: solid 1px $border-color;
  border-left: solid 1px $border-color;
}

.chat {
  flex: 1;
  background: $background-color;
  overflow: auto;
}

.sessionsEmpty {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 14px;
  color: #999999;
}

.loader {
  display: flex;
  justify-content: center;
}
