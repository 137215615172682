@import "@/common/scss/variables.scss";

.leftMenu {
  background: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.addGroup {
  height: 40px;
  padding: 0 17px;
  background: #fff;
  color: #116FFC;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color .3s ease-out;
  border-bottom: 1px solid $border-color;
  margin-bottom: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .10);

  &:hover {
    color: rgba($color-blue, 0.7);
  }
}

.addGroupPlus {
  margin-right: 10px;
}

.dropDownDisabled[disabled] {
  .addGroup {
    color: #dddddd;
    svg {
      path {
        fill: #dddddd;
      }
    }
  }
}
