.messageButton {
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 14px 20px;
    color: #02a7e3;
    font-size: 14px;
    width: 100%;
    transition: color 0.3s ease-out;
    cursor: pointer;
    background-color: white;
    &:hover {
        color: rgba(#02a7e3, 0.7);
    }
}
