@import "../../../common/scss/variables";

.branches {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 0 16px;
}

.branch {
  background-color: white;
  border: none;
  border-radius: 5px;
  color: $gray-color;
  font-size: 14px;
  padding: 12px 30px;
  margin-right: 8px;
  margin-top: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.active {
  box-shadow: none;
  animation: activate .25s ease-out forwards;
}

.deactivate {
  animation: deactivate .25s ease-out forwards;
}


.connect {

  height: 20px;
  width: 2px;
  background-color: $color-blue;
  z-index: 1;
  margin: -4px 0;
}

.branchInner {
  margin: -7px 0;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.margin {
  margin-top: 20px;
}

@keyframes activate {
  from {
    background-color: white;
    color: $gray-color;
  }
  to {
    background: $blue-color;
    color: white;
  }
}

@keyframes deactivate {
  from {
    background: $blue-color;
    color: white;
  }
  to {
    background-color: white;
    color: $gray-color;
  }
}
