.wrapper {
  display: flex;
  height: 100%;
  padding: 32px 12px 0 0;
}

.label {
  color: #999 !important;
}

.loader {
  display: flex;
  height: 100%;
}
