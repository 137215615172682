@import '@/common/scss/variables.scss';
@import '@/common/scss/mixins.scss';

.tip {
  width: 100%;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $gray-color;

  p {
    color: $gray-color !important;
    text-align: center;
  }
}

.entityList {
  width: 100%;
  background-color: white;
  height: 100%;
  padding: 15px 0 0;
  display: flex;
  flex-direction: column;
  overflow: auto;

  span {
    margin-right: 5px;
  }
}

.editor {
  position: relative;
  padding: 0 15px;
}

.entityHeader {
  margin-bottom: 28px;
  padding: 0 15px;
}

.entitiesViewToggle {
  border: none;
  border-bottom: 1px dashed $gray-color;
  font-size: 14px;
  color: $gray-color;
  background: transparent;
  margin-right: 30px;
  z-index: 1000;
}

.selected {
  position: relative;
  padding: 2px 5px 2px 3px;
  background-color: $blue-color;
  color: white;
}

.selected:after {
  content: '|';
  position: absolute;
  right: 1px;
  color: $primary-text-color;
}

.title {
  @include editableTitle;
}

.errorMessage {
  color: #e0536f;
}

.loader {
  display: flex;
  height: 100%;
}

.header {
  @include header;
}

.headerButtons{
  display: inherit;
  justify-content: flex-end;
}
