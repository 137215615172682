@import "@/common/scss/variables";

.wrapper {
  margin: 38px 20px 0;
}

.container {
  margin: 38px 225px 38px 118px;

  @media screen and (max-width: 1360px) {
    width: 95%;
    margin: 38px auto;
  }
}

.title.title {
  margin-bottom: 36px;
  font-size: 24px;
  color: $primary-text-color;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.titleLink {
  font-size: 14px;
  line-height: 16px;
  color: #116FFC !important;
  cursor: pointer;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.usersList {
  list-style: none;
  padding: 0;
}

.usersListItem:not(:last-child) {
  margin-bottom: 20px;
}
