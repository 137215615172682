$themes: (
        light: (
                colorBackground: white,
                colorBackgroundBody: #f2f4f7,
                colorText: #646777,
                colorTextAdditional: #646777,
                colorHover: #fafbfe,
                colorFolderHover: #f0eeee,
                colorBorder: #eff1f5,
                colorIcon: #dddddd,
                imgInvert: invert(0%),
                colorFieldsBorder: #f2f4f7,
                colorBubble: rgba(242, 244, 247, 0.65),
                colorBubbleActive: rgba(234, 238, 255, 0.6),
                colorScrollbar: #B4BFD0,
                colorFitness: #646777,
        ),
        dark: (
                colorBackground: #232329,
                colorBackgroundBody: #2a2a31,
                colorText: #dddddd,
                colorTextAdditional: #999999,
                colorHover: #38373f,
                colorFolderHover: #ffffff1A,
                colorBorder: #333246,
                colorIcon: #605f7b,
                imgInvert: invert(100%),
                colorFieldsBorder: #33333a,
                colorBubble: rgba(68, 79, 97, 0.65),
                colorBubbleActive: rgba(92, 104, 156, 0.6),
                colorScrollbar: #606071,
                colorFitness: #ffffff
        )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-accent: #116FFC;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #E0536F;
$color-red-hover: darken($color-red, 10%);

$color-blue: #116FFC;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
