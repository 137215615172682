.modalContent {
  padding: 10px !important;
}

.modal {
  max-width: 600px !important;
}

.backdrop {
  background-color: white !important;
  opacity: .7;
}
