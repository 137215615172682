.node {
  position: relative;
  width: 100%;
  min-height: 60px;
  height: 100%;
  background: #116FFC;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  border-radius: 5px;
}

.node:hover {
  .delete {
    opacity: 1;
  }
}

.delete {
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
  opacity: 0;
  transition: opacity .25s ease-out;
}
