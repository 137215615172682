@import '@/common/scss/mixins.scss';

.editableTitle {
  @include editableTitle;
}

.loader {
  width: 90px;
  height: 90px;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.header {
  @include header;
}
