.leftLiaBaloon {
  background-image: url('../../imgs/bgsteps.png');
  position: absolute;
  left: 0;
  top: 0;
  width: 770px;
  height: 580px;
  background-size: contain;
  background-repeat: no-repeat;
}

.rightLiaBaloon {
  background-image: url('../../imgs/bgsteps2.png');
  position: absolute;
  right: 0;
  bottom: 0;
  width: 583px;
  height: 817px;
  background-size: contain;
  background-repeat: no-repeat;
}

.logo {
  background-image: url('../../imgs/logo.svg');
  width: 77px;
  height: 33px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 5px;
}
