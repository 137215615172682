@import "../../../common/scss/variables.scss";

.arrow {
  text-align: center;
  margin-bottom: 10px;
}

.over {
  opacity: .3;
}

.drop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  border: 2px dashed $blue-color;
  border-radius: 5px;
}

.dropWrapper {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 300;

  border-radius: 5px;
  background-color: rgba(255,255,255,.6);
  color: #999;
  font-size: 18px;
  padding: 10px;
  text-align: center;
}
