@import "@/common/scss/variables.scss";

.intents {
  min-height: 100%;
  padding: 20px 16px;
}

.group {
  position: relative;
}

.group:not(:last-child) {
  margin-bottom: 36px;
}

.groupTitle {
  font-size: 14px;
  color: $primary-text-color;
  margin-bottom: 16px;
}

.how {
  position: absolute;
  right: 0;
  top: 0;

  font-size: 14px;
  color: $blue-color;
}

.tooltip {
  max-width: 300px;
}
