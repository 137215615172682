.modalContent {
    padding: 30px 10px 30px 10px !important;
    display: flex !important;
    align-items: center;
    max-width: 320px;
}

.backdrop {

}

.subtitle {
    color: #999999;
    font-size: 13px;
    margin-bottom: 30px;
}

.title {
    font-size: 13px;
    margin-bottom: 12px;
    margin-top: 28px;
}

.wrapper {
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cross {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #999;
    cursor: pointer;
}

.waiting {
    background-color: #B893FD !important;
    border-color: #B893FD !important;
}
