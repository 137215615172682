@import "@/common/scss/variables.scss";

.popper {
  width: 340px;
  max-height: 50vh;
  overflow-y: auto;
  background-color: white;
  padding: 0 1em 1em;
  min-width: 100px;

  &::before {
    border-width: 0 1.5em 1em 1.5em;
    border-color: transparent transparent #232323 transparent;
  }
}

.listHeader {
  padding-left: 15px;
  font-size: 14px;
  color: $gray-color;
}

.listItem {
  cursor: pointer;
  padding-bottom: 10px;
  color: black;
  position: relative;
  &:before {
    content: "•";
    position: absolute;
    font-size: 3em;
    left: -20px;
    top: -20px;
    display: inline-block;
  }
}

.secondary,
.primary,
.blue {
  list-style: none;
}

.secondary {
  .listItem::before {
    color: $secondary-color;
  }
}

.primary {
  .listItem::before {
    color: $primary-color;
  }
}

.blue {
  .listItem::before {
    color: $blue-color;
  }
}

.type {
  color: $gray-color;
  float: right;
}

.hr {
  width: 100%;
  margin: 0 .5em;
}
