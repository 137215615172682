@import "@/common/scss/variables.scss";

.tag {
  padding: 3px 5px;
  border-radius: 5px;
  color: white;
  background-color: $blue-color;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
