@import "@/common/scss/variables.scss";

.user {
  padding: 10px 15px;
  background: white;
  border-bottom: 1px solid $border-color;
  position: relative;
  cursor: pointer;
}

.user:hover {
  background-color: rgba(0,0,0,.1);
}

.active {
  .userName {
    color: $primary-color;
  }
}

.loading {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  top: 4px;
  padding: 0 8px;
}

.userName {
  font-size: 12px;
  line-height: 19px;
}

.date {
  font-size: 10px;
  color: $gray-color;
}
.platform {}
