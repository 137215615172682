@import '@/common/scss/mixins.scss';

.entityTitle {
  @include editableTitle;
}

.leftActionsMenu {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.pageConent {
  margin-top: 24px;
}

.header {
  @include header;
}
