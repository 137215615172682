@import "@/common/theme/settings/variable.scss";

.list {
  list-style: none;
  padding: 0;
  overflow: auto;
  height: calc(100% - 40px);
}

.link.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 17px;

  &.activeLink .title,
  &:hover .title {
    color: $color-blue;
  }
}

.messengerIcon {
  background: url('./imgs/messenger.svg') no-repeat;
  width: 15px;
  height: 15px;
  background-size: contain;
}

.liaIcon {
  background: url('./imgs/lia.svg') no-repeat;
  width: 15px;
  height: 15px;
  background-size: contain;
}

.title {
  margin-left: 15px;
  font-size: 14px;
  color: #444;
  transition: color 0.3s ease-out;
}

.disconnected,
.connected {
  right: 15px;
  width: 7px;
  height: 7px;
  top: 50%;
  background: $color-blue;
  border-radius: 50%;
  margin-left: 10px;
}

.disconnected {
  background: #999;
}

.menu {
  height: 100%;
}

.label {
  cursor: pointer;
  padding: 5px 5px 5px 9px !important;
  & :global(.dropdown-item) {
    padding: 0;
  }

  & :global(.dropdown-item.disabled) {
    pointer-events: visible!important;
  }
}

.itemLeft {
  display: flex;
  align-items: center;
}

.connectIcon {
  margin: 0 10px;
  width: 20px;
  height: 20px;
}

.channelIcon {
  width: 20px;
  height: 20px;
}
