.app {
    /*background-color: rgb(242, 244, 247);*/
    background-color: #F5F5F8;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.link {
    color: #61dafb;
}

.wrapper {
    position: relative;
    overflow-y: auto;
    flex: 1;
}

.header,
.content {
    position: relative;
}

.content {
    display: flex;
    height: calc(100% - 71px);
    z-index: 900;
}

.header {
    z-index: 1000;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.09);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
