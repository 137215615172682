.tag {
  cursor: pointer;
  user-select: none;
  padding: 0 5px;
  line-height: 2em;
}

.pointer {
  cursor: pointer;
  margin-left: 3px;
}
