.title {
  font-size: 14px;
  font-weight: bold;
}


.popup {
  &:nth-child(1) {
    height: 90vh;
  }
}

.dropdownItem {
  height: 32px;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  cursor: pointer;
  padding: 0 12px;
  justify-content: space-between;

  &:hover {
    background-color: #E6F7FF;
  }
}

.dropdownItemSelected {
  background-color: #E6F7FF;
}

.searchInTitle {
  font-weight: bold;
  margin: 10px 0;
}

.autoCompleteWrapper {
  display: flex;
}
