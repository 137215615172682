@mixin editableTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  @media screen and (max-width: 1480px) {
    font-size: 22px;
    line-height: 32px;
  }
}

@mixin card {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

@mixin input {
  font: 14px/16px Roboto, sans-serif;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  height: 40px;
}

@mixin header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
