.link {
  text-decoration: none;
  color: white;
  border: none;
  transition: none;

  &:hover {
    color: white;
    border-bottom: 2px dashed white;
  }

  &Dark {
    text-decoration: none;
    border: none;
    transition: none;
    color: #444;

    &:hover {
      color: #444;
      border-bottom: 2px dashed #444;
    }
  }
}

