@import '@/common/scss/mixins.scss';

.entityTitle {
  @include editableTitle;
}

.entitySubtitle {
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  margin-top: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 30px;
}

.description {
  color: gray;
  margin-top: -10px;
  margin-bottom: 10px;
}