.wrapper {
  height: 100%;
}

.title {
  margin-bottom: 24px;
}

.label {
  color: #999 !important;
}

.loader {
  display: flex;
  height: 100%;
}
