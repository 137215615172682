@import "../../../common/scss/variables";

.state {
  position: relative;
  padding: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;

  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

  margin-top: 15px;
}

.stateName {
  text-align: center;
  font-size: 14px;
  color: $primary-text-color;
  margin-bottom: 12px;
}

.children {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
}

.drop {
  width: 100px;
  height: 100%;
  min-height: 60px;
  border: 2px dashed $blue-color;
  border-radius: 5px;
  transition: margin .2s, height .2s, flex .2ms;
  opacity: 1;
  margin-right: 12px;
}

.hide {
  display: none;
  opacity: 0;
}

.dropOver {
  opacity: .3;
}

.fallback {
  user-select: none;
  flex: 0 0 100px !important;

  &:nth-child(2) {
    flex: 1;
  }
}

.inactive {
  opacity: .75;
  cursor: pointer;
  transition: opacity .25s ease-out;
}

.active {
  opacity: 1;
}

.trigger {
  flex: 1;
  // min-width: 95px;
  min-width: fit-content;
  margin: 0px 5px 10px 5px;
}

.state:hover {
  .delete {
    opacity: 1;
  }
}

.delete {
  display: block;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  opacity: 0;
  transition: opacity .25s ease-out;
}
