@import "../../common/scss/variables";
@import '@/common/scss/mixins.scss';

.container {
    margin: 38px 225px 0 118px;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title.title{
    font-size: 22px;
    color: #1b1e21;
    margin-bottom: 20px;
}

.header > .title.title {
    margin-bottom: 0;
}

.subTitle {
    float: right;
    font-size: 14px;
    font-weight: normal;
    color: $gray-color;
}

.cardList {
    padding: 0;
    margin: 0 0 0 -6px;
    list-style: none;
}

.cardListItem:not(:last-child) {
    margin-bottom: 18px;
}

.savedVersionsContainer {
    margin-top: 60px;
    padding: 50px 0;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        border-top: 2px dashed #BBBBBB;
        top: 0;
        left: -42px;
        right: -42px;
    }
}

.cardBtn.cardBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 45px;

    svg {
        height: auto;
        margin: 0 16px 0 0;
    }
}

.cardBtnPublish.cardBtnPublish {

    svg {
        width: 14px;
    }
}

.cardBtnRestore.cardBtnRestore {

    svg {
        width: 16px;
        fill: currentColor !important;
        color: $blue-color;
    }
}

.modalContent.modalContent {
    padding: 30px 46px 42px;
    line-height: 18px;
    border-radius: 10px;
}

.modalContentRestore.modalContentRestore {
    width: 370px;
}

.modalContentRemove.modalContentRemove {
    width: 300px;
}

.modalBackdrop.modalBackdrop.modalBackdrop {
    background-color: rgba(57, 61, 65, 0.93) !important;
}

.modalClose.modalClose {
    border: none;
    display: inline-flex;
    position: absolute;
    top: 12px;
    right: 10px;
    padding: 5px;

    svg {
        margin: 0;
        width: 10px;
        height: auto;
    }
}

.modalIcon {
    color: #D8D8D8;
    margin-bottom: 28px;
}

.modalTitle {
    margin-bottom: 6px;
}

.modalText {
    color: $gray-color;
    margin-bottom: 31px;
}

.modalBtn.modalBtn {
    height: 38px;
    padding: 8px 41px 12px;
}

.modalBtnPrimary {
    width: 220px;
}

.header {
    @include header;
}
