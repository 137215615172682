@import "@/common/scss/variables.scss";

.item {
  display: inline-flex;
  width: 80px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $gray-color;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  user-select: none;
  cursor: pointer;

  margin-right: 4px;
  margin-bottom: 4px;

  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.054);
  }
}

.hide {
  opacity: 0;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.dragged:not(.reaction) {
  background: $blue-color;
  color: white;
  border: none;
}
