@import '@/common/scss/mixins.scss';

.projectSettings {
  margin-top: 50px;
  & :global(.material-form__field) {
    width: 100%;
  }
}

.projectSettingsIntentTitle {
  font-size: 13px;
  color: #646777;
}

.projectSettingsSlider {
  //width: 1px;
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.projectSettingsTitle {
  margin-bottom: 25px;
}

.projectCardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectFormGroup {
  width: 50%;
}

.slider {
  width: 100%;
  margin-bottom: 25px;
  & :global(.slider__min) {
    display: none;
  }

  & :global(.slider__max) {
    display: none;
  }
}

.title{
  @include editableTitle;
  color: #1b1e21 !important;
}
.header{
  @include header;
}

.variablesTable {
  margin-top: 20px;
  width: 100%;
}

.variablesTableHeader {
  display: flex;
  font-size: 14px;
  background-color: #FAFAFA;
  padding: 16px 24px 16px 24px;
  gap: 20px;
  border-bottom: 1px solid #EBEBEB;
}

.variablesTableHeaderCell {
  flex: 1;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delimetr {
  background-color: #EBEBEB;
  height: 100%;
  width: 2px;
}

.variablesTableBody {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.variablesTableAction {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.3);
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.variablesRow {
  display: flex;
  background-color: #fff;
  padding: 16px 24px 16px 24px;
}

.variablesCell {
  flex: 1;
}

.variablesActions {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 164px;
}

.fullSizeWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
