@import "@/common/scss/variables.scss";

$chat-cloud-color: #d1f1fd;

.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fafbfe;
	position: relative;
}

.userText {
	max-width: 100%;
	word-break: break-word;
}

.actions {
	height: 40px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-bottom: 1px solid #ebedef;
}

.actionIcon {
	margin-left: 15px;
	cursor: pointer;
	transition: opacity 0.3s ease-in-out;

	&:hover {
		opacity: 0.6;
	}
}

.facts {
	width: 383px;
	background: #fff;
	height: 248px;
	display: flex;
	flex-direction: column;
	padding: 22px 16px 19px 16px;
}

.factsText {
	flex: 1;
}

.chatInputs {
	display: flex;
	height: 60px;
	align-items: center;
	padding: 14px;
}

.tooltip {
	z-index: 2147483647;
	background-color: #fff;
	padding: 8px 15px 0;
	box-shadow: 0 1px 30px rgba(0, 0, 0, 0.110252);
	border-radius: 5px;
}

.factsTextArea {
	height: 100%;
	width: 100%;
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	padding: 10px;
	resize: none;
	font-family: monospace;
}

.factsActions {
	margin-top: 12px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.factsTitle {
	font-size: 14px;
	margin-bottom: 14px;
}

.factsSave {
	padding-left: 20px;
	padding-height: 20px;
}

.chatInput {
	position: relative;
	flex: 0 60px;
	max-height: 100px;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	justify-self: flex-end;
	background-color: white;
	border-top: 1px solid $border-color;

	textarea {
		width: 100%;
		color: $primary-text-color;
		font-size: 14px;
		line-height: 20px;
		padding: 5px 35px 5px 10px;
		box-sizing: border-box;
		border-radius: 4px;
		border: 1px solid #ebedef;
	}
}

.reset {
	position: absolute;
	top: -25px;
	right: 20px;
	color: $color-accent;
	cursor: pointer;
	transition: color 0.3s ease-out;

	&:hover {
		color: rgba($color-accent, 0.7);
	}
}

.start {
	position: absolute;
	top: -25px;
	right: 60px;
	color: $color-accent;
	cursor: pointer;
	transition: color 0.3s ease-out;

	&:hover {
		color: rgba($color-accent, 0.7);
	}
}

.list {
	flex: 1;
	overflow: auto;
}

.scroll {
	position: relative;
	min-height: 100%;
	width: 100%;
	padding: 10px 15px;
	margin-top: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
}

.cloud {
	max-width: 95%;
	padding: 15px;
	font-size: 14px;
	color: $primary-text-color;
	border-radius: 5px;
	box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.13);
	margin-bottom: 15px;

  	&:last-child {
		margin-bottom: 30px;
	}

	input {
		height: 26px;
		border: 1px solid $border-color;
		border-radius: 0;
		padding: 0 6px;

		font-size: 12px;

		margin-top: 6px;
		width: 100%;
	}
}

.proba {
	color: $gray-color;
	font-size: 12px;
	margin-left: 4px;
}

.loaderWrapper {
	margin: 6px;
}

.intentProba {
	display: flex;
}

.customMessage {
	padding: 15px;
	margin-bottom: 20px;
	align-self: flex-end;
	background-color: #d1f1fd;
	color: #444444;
	border-radius: 5px;
	box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, 0.13);
}

.replies {
	position: absolute;
	width: 100%;
	bottom: 60px;
}

.userMessage {
	align-self: flex-end;
	background-color: #d1f1fd;
	white-space: pre-wrap;
	overflow-wrap: break-word;
}

.botMessage {
	background-color: white;
	box-sizing: border-box;
	border: 1px solid $border-color;
	white-space: pre-wrap;
}

.input {
	position: relative;
	width: 100%;
	height: 32px;
}

.inputWrapper {
	display: flex;
	align-items: center;
	flex: 1;
	position: relative;
	margin: 0 10px;
}

.emojiPicker {
	fill: $gray-color;
	position: absolute;
	right: 10px;
	top: 4px;
	transition: opacity 0.3s ease-out;

	&:hover {
		opacity: 0.5;
		cursor: pointer;
	}
}

.attachment {
	fill: $gray-color;
}

.voice {
	fill: $blue-color;
}

.table {
	width: 300px;
	display: flex;
	flex-direction: column;
	white-space: normal;
}

.row {
	display: flex;
	flex-direction: row;
	margin-top: 5px;
}

.header {
	flex: 20% 0;
	font-size: 12px;
}

.values {
	flex: 1;
	align-self: flex-start;
	font-size: 12px;
	color: $blue-color;
	display: flex;
	flex-direction: column;
}

.valueRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	border-bottom: 1px solid $border-color;

	&:last-child {
		border-bottom: none;
	}
}

.value {
	color: $primary-text-color;
	word-break: break-word;
}

.unanswered {
	margin-left: 5px;
	color: $red-color;
	font-size: 12px;
}

.emptyChatWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.emptyChatIcon {
	width: 48px;
	height: 48px;
	background: url("../images/empty-chat.png") no-repeat;
	background-size: contain;
}

.emptyChatText {
	color: #999999;
	max-width: 150px;
	margin-top: 10px;
	text-align: center;
}

.chatLocationShare {
	background: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 384px;
	height: 100%;
	max-height: 400px;
	z-index: 111;
}

@keyframes :global(blink) {
	50% {
		opacity: 1;
	}
}

@keyframes :global(bulge) {
	50% {
		transform: scale(1.05);
	}
}

.typingIndicator {
	background-color: #f8f8f8;
	will-change: transform;
	width: auto;
	border-radius: 50px;
	padding: 12px;
	display: table;
	position: relative;
	margin-bottom: 14px;

	:global {
		animation: 2s bulge infinite ease-out;
	}

	//&::before,
	//&::after {
	//  content: '';
	//  position: absolute;
	//  bottom: -2px;
	//  left: -2px;
	//  height: 20px;
	//  width: 20px;
	//  border-radius: 50%;
	//  background-color: #E6E7ED;
	//}
	//&::after {
	//  height: 10px;
	//  width: 10px;
	//  left: -10px;
	//  bottom: -10px;
	//}
	span {
		height: 6px;
		width: 6px;
		float: left;
		margin: 0 1px;
		background-color: #9e9ea1;
		display: block;
		border-radius: 50%;
		opacity: 0.4;

		@for $i from 1 through 3 {
			&:nth-of-type(#{$i}) {
				:global {
					animation: 1s blink infinite ($i * 0.3333s);
				}
			}
		}
	}
}

.typingWrapper {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.marked {
	color: #116FFC;
	font-size: 12px;
	display: flex;
	align-items: center;
	margin-top: 5px;
}

.addedTo {
	margin-right: 10px;
}

.actionsDescription {
	cursor: pointer;
	font-size: 12px;
	color: #116FFC;
}

.disabledSendMessage {
	& path {
		fill: #d1d1d1;
	}
}

.errorMsg {
	color: $red-color;
	font-size: 0.875em;
	flex: 1;
}

.factsTextAreaHasError {
	border-color: $red-color;
}
