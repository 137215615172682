$primary-text-color: #444444;
$white-text-color: #FFFFFF;

$primary-color: #116FFC;
$secondary-color: #B893FD;

$blue-color: #116FFC;
$gray-color: #999999;
$red-color: #E0536F;
$border-color: #EBEDEF;
$hover-light-color: #FAFBFE;
$color-accent: #116FFC;
$color-blue: #116FFC;
$color-primary: #007bff;

$background-color: #f2f4f7;

$input-border-color: #eaeaea;

