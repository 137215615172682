@import '@/common/theme/settings/variable.scss';
@import '@/common/scss/variables.scss';

.sidebarSearch {
  margin-top: 30px !important;
}

.sidebarSearchIntents {
  margin-top: 10px !important;
}

.searchWrapper {
  margin-top: 10px;
  overflow: auto;
}

.menuScroll {
  overflow: auto;
}

.collapse {
  margin-top: 10px;
  overflow-x: auto;
  & :global(.collapse__content) {
    padding-left: 0;
    padding-right: 0;
  }

  & :global(.collapse__title) {
    display: inline-flex;
    justify-content: space-between;
    padding-left: 17px;

    & p {
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }

    & svg {
      position: static;
    }

    & :global(.collapse__title-chevron) {
      min-width: 30px;
      height: 24px;
      margin-top: 7px;
      margin-right: -7px;
    }
  }
}

.loadMore {
  color: $color-accent;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.5;
  }
  svg {
    color: gray;
  }
}

.importControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.importControlsCheckboxChecked {
  color: $color-accent !important;
}

.importControlsBtns {
  display: inline-flex;
}

.importControlsImportBtn.importControlsImportBtn {
  margin-left: 10px;
  min-width: 138px;
}

.importExportDropdown.importExportDropdown {
  display: inline-block;
  position: absolute;
  right: 12px;

  .importExportDropdownToggle.importExportDropdownToggle {
    display: inline-block;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .importExportDropdownMenu.importExportDropdownMenu {
    left: initial !important;
    right: -12px !important;
    width: auto !important;
    min-width: auto;
    padding: 0;
  }

  .importExportDropdownToggleItem.importExportDropdownToggleItem {
    text-align: right;
  }
}

.importExportDropdownToggleIcon.importExportDropdownToggleIcon {
  display: inline-block;
  width: 18px;
  height: 18px;
  fill: $color-blue;
  right: 12px;
  margin: 0;
}

.importReady {
  width: 580px;
  height: 220px;

  border: 1px dashed $border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: $gray-color;
    font-size: 18px;
    font-weight: normal;
  }
}

