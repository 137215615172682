.minimalPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &::before,
  &::after {
    content: '';
    display: flex;
    position: fixed;
    background-repeat: no-repeat;
    background-color: transparent;
    pointer-events: none;
  }

  &::before {
    top: 0;
    left: 0;
    width: 467px;
    height: 509px;
    background-image: url(./imgs/bgtop.svg);
  }

  &::after {
    bottom: 0;
    right: 0;
    width: 470px;
    height: 304px;
    background-image: url(./imgs/bgbottom.svg);
    background-position: bottom right;
  }
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
