@import "@/common/scss/variables.scss";
@import "@/common/scss/mixins.scss";

.convert,
.convert:hover,
.convert:visited {
  color: #B893FD !important;
  text-decoration: none;
}

.convert:hover {
  color: darken(#B893FD, 10%);
}

.link,
.link:hover,
.link:visited {
  color: #116FFC;
  text-decoration: none;
  margin-right: 12px;
}

.link:hover {
  color: darken(#116FFC, 20%);
}

.title {
  margin: 0;
  color: $primary-text-color !important;
  @include editableTitle;
}

.editIcon {
  cursor: pointer;
}

.header {
  @include header;
}

.help {
  color: $secondary-color !important;
  border-color: $secondary-color !important;
}

.formLabel {
  color: $gray-color !important;
}

.answers {
}

.answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background: white;
  border-radius: 4px;
  font-size: 14px;
  font-style: italic;
  color: $primary-text-color !important;
  margin-bottom: 8px !important;
  &:hover {
    & .editQuestionIcon {
      opacity: 1;
    }
  }
}

.questions {
  border-radius: 5px;
  min-height: calc(70% + 7px);
  background: white;
}

.question {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  color: $primary-text-color;
  font-size: 14px;
  line-height: 1.2;

  &:hover {
    & .editQuestionIcon {
      opacity: 1;
    }
  }
}

.question:hover {
  background-color: rgba($gray-color, 0.16);

  .questionDot {
    fill: #fff;
  }
}

.questionForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 0;

  form {
    flex: 1;
  }

  :global {
    .form__form-group {
      margin-bottom: 0;
    }
  }

  svg {
    margin-right: 15px;
  }
}

.questionDot {
  flex: 16px 0;
  align-self: flex-start;
  margin-top: 4px;
  margin-left: 2px;
  margin-right: 16px;
  fill: #EBEDEF;
}

.questionClose {
  cursor: pointer;
  margin-left: auto;
}

.text {
  margin: 0;
  max-width: 80%;
}

.addAnswerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  form {
    flex: 1;
  }
}

.answerText {
  white-space: pre-wrap;
  padding: 2px 0;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  font-size: 14px;
  font-style: normal;
}

.addAnswer {
  margin-top: -8px;
}

.addAnswer:hover {
  margin-right: 0;
}

@for $i from 1 through 10 {
  .fill#{$i} {
    background-color: linear-gradient(to right, $primary-color $i * 10%, lighten($primary-color, 30%) (10 - $i) * 10% );
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.chat {
  width: 400px;
}

.editor {
  height: 100%;
}

.answerRemove {
  float: right;
  cursor: pointer;
}

.editQuestionIcon {
  opacity: 0;
  margin-right: 5px;
  transition: opacity 0.3s ease-out;
}

.editForm {
  width: calc(100% - 80px);
}

.editInput {
  width: 100%;
  & input {
    font-size: 14px;
    padding-top: 0;
  }
}

.tableWrapper {
  margin-top: 20px;
}

.row {
  cursor: pointer;
}
