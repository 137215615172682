.title.title {
  font-weight: normal;
  font-size: 155px;
  color: #000000;
  line-height: 1;
}

.text {
  font-size: 28px;
}
