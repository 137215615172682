@import "@/common/scss/variables.scss";

.node {
  width: 100%;
  height: 100%;
  min-width: fit-content;

  color: #444;
  position: relative;

  display: flex;
  flex-direction: column;

  border-radius: 5px;
  background-color: white;

  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

  flex: 1;

  cursor: pointer;
  overflow: hidden;
}

.selector {
  display: inherit;
  position: relative;
  width: 95%;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  font-size: 14px;
  color: #444444;
  background-color: white;
  padding: .25rem .5rem;
  z-index: 1;
}

.option {
  position: relative;
  padding: .25rem .8rem;
}


.header {
  flex: 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;

  background-color: rgba(0, 0, 0, .04);
}

.title {

}

.content {
  flex: 1;
  font-size: 16px;
  padding: 10px 15px;
  word-break: break-word;
}

.node:hover {
  .delete {
    opacity: 1;
  }
}

.delete {
  display: block;
  cursor: pointer;
  opacity: 0;
  transition: opacity .25s ease-out;
}

.reaction.node {
  margin-top: 15px;
}

.trigger {
  color: white;
  background-color: #116FFC;
}

.trigger {
  .header {
    background-color: rgba(0, 0, 0, .12);
  }
}

.drop {
  width: 100%;
  height: 40px;
  margin-top: 16px;
  border: 2px dashed $blue-color;
  border-radius: 5px;
  transition: margin .2s, height .2s, flex .2ms;
  opacity: 1;
}

.dropOver {
  opacity: .3;
  height: 60px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.dropRow {
  flex: 0 0 40px;
  margin-left: 16px;
  border: 2px dashed $blue-color;
  border-radius: 5px;
  transition: margin .2s, height .2s, flex .2ms;
  opacity: 1;
}

.dropRowOver {
  opacity: .3;

  flex: 0 0 120px;
}

.hideDrop {
  flex: 0;
  margin: 0;
  height: 0;
  border: none;
}

@media screen and (max-width: 1480px){
  .header {
    padding: 2px 11px;
  }

  .content {
    font-size: 15px;
    padding: 7px 11px;
  }
}
