@import "@/common/scss/variables.scss";

.root {
  width: 580px;
  height: 220px;

  border: 1px dashed $border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: $gray-color;
    font-size: 18px;
    font-weight: normal;
  }
}

.sample {
  cursor: pointer;
  color: $blue-color !important;
  font-size: 11px;
}
