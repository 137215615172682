.zeroScreenIcon {
  background: url(./imgs/zero-icon.svg) no-repeat;
  background-size: contain;
  width: 85px;
  height: 85px;
}

.zeroScreenTitle {
  margin-top: 28px;
  font-size: 18px;
  color: #999999;
}

.zeroScreenSubTitle {
  font-size: 14px;
  line-height: 26px;
  color: #999999;
  text-align: center;
}

.zeroScreenWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.header{
  position: absolute;
  top: 20px;
  right: 20px;
}
