@import "../../scss/variables";

.dropdown {

  &:last-child .btn {
    margin-right: 0;
  }

  .btn.icon svg {
    height: 18px;
    width: 20px;
    margin-right: -5px;
  }

  .btn-group {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    .btn {

      &:last-child {
        border-left: none;
        padding: 7px;

        svg {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.dropdown__menu {
  min-width: 170px;
  max-width: 380px;
  border: none;
  margin: 0;
  padding: 13px 0 0;
  background: #FFFFFF;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  font-size: 14px;

  transition: all .2s ease-out;
  opacity: 0;
  user-select: none;

  &.show {
    opacity: 1;
  }

  @include themify($themes) {
    background: themed('colorBackground');
  }

  .dropdown-item {
    transition: all 0.2s ease-out;
    padding: 0 22px;
    cursor: pointer;
    border-radius: 0;
    line-height: 2.5;

    @include themify($themes) {
      color: themed('colorText');
    }

    &--title {
      color: $gray-color !important;
    }

    &--control {
      font-weight: 500;
      margin-top: -8px;
      color: $color-blue !important;
      padding-top: 6px;
      padding-bottom: 6px;
      text-align: center;
    }

    &:hover {
      color: $color-blue;
      background-color: rgba(10, 210, 200, 0.1);
    }

    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }
  }
}
