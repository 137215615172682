@import "../../../common/scss/variables";
@import "../../../common/scss/mixins";

.card {
  @include card;
  padding: 14px 13px 18px 26px;
}

.cardTitle,
.cardText {
  font-size: 14px;
  line-height: 34px;
}

.cardTitle.cardTitle {
  color: #000;
  font-weight: 500;
  line-height: 2.2;
}

.cardText {
  color: $gray-color;
  line-height: 1;
}

.cardExport.cardExport {
  padding: 10px 12px;


  svg {
    width: 20px;
    height: auto;
    margin: 0;
    color: $blue-color;
    fill: currentColor !important;
  }
}

.cardControls {
  display: inline-flex;
  align-items: center;
}

.cardControl {
  display: inline-flex;
  margin-left: 12px;
}

.cardRemoveBtn.cardRemoveBtn {
  position: absolute;
  display: inline-flex;
  border: none;
  padding: 10px !important;
  left: calc(100% + 7px);
  top: calc(50% - 18px);

  svg {
    margin: 0;
    fill: currentColor !important;
    color: $red-color;
    width: 16px;
    height: auto;
    opacity: 0;
  }
}

.card:hover .cardRemoveBtn svg {
  opacity: 1;
}

.cardError {
  width: 230px;
  margin-right: 12px;
  font-size: 14px;
  line-height: 16px;
  color: #FF4861;
  text-align: right;
}

.cardEditableTitle {
  display: inline-flex;
}

.cardTitleHint {
  color: $gray-color;
  font-weight: normal;
  font-size: 12px;
  margin-left: 6px;
}

.updateHappened {
  animation: updateBlink .5s linear infinite alternate;
}

@keyframes updateBlink {
  from { opacity: .3 }
  to { opacity: 1 }
}
