@import "@/common/scss/variables.scss";

.projectCreateModal {
  position: relative;
  width: 716px;
  height: 490px;
  transition: all .3s ease-out;
  overflow: hidden;
}

.projectCreateModalNameEnter {
  height: 226px;
  width: 455px;
}

.projectCreateContent {
  padding: 25px 0 24px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  transition: all .3s ease-out;
  opacity: 1;
  transform: translateX(0);
}

.projectEnterNameWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all .3s ease-out;
  transform: translateX(100%);

}

.projectCreateModalNameEnter .projectEnterNameWrapper {
  position: relative;
  height: 100%;
  opacity: 1;
  transform: translateX(0);
}

.projectCreateModalNameEnter .projectCreateContent {
  opacity: 0;
  position: absolute;
  transform: translateX(-100%);
}

.projectsWrapper {
  height: 440px;
  padding: 15px 34px 0 31px;
  overflow: auto;
  flex: 1;
}

.projectWrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  transition: border-color .2s ease-out;

  &:hover {
    border-color: $blue-color;

    & .projectArrow {
      opacity: 1;
    }
  }
}

.projectArrow {
  opacity: 0;
  transition: opacity .2s ease-out;
  color: $blue-color;
  display: flex;
  align-items: center;
  padding: 10px;
}

.projectImage {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  margin-right: 16px;
  overflow: hidden;
}

.projectTitle {
  margin-top: 4px;
  text-align: left;
  font-size: 14px;
}

.projectSubtitle {
  text-align: left;
  font-size: 12px;
  color: $gray-color;
  margin-top: 4px;
}

.buttonCreate {
  width: 136px;
  padding: 8px 10px !important;
}

.projectRight {
  flex: 1;
}

.cancelButton {
  border-color: transparent !important;
  color: $primary-text-color !important;
}

.projectCreate {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 20px 0 32px;
}

.projectCreateInput {
  height: 42px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  width: 208px !important;
  &::placeholder {
    color: $gray-color;
  }
}

.projectCreateInputIcon {
  width: 40px;
  height: 38px;
  background-color: #F2F4F7;
}

.opacityProject {
  opacity: 0.4;
}

.projectCreateLangs {
  min-height: 100px;
  flex: 0 0 100px;
  border-right: 1px solid #DDDDDD;
  margin-top: 10px;
}

.projectCreateLangsList {
  list-style: none;
  padding: 0;
  text-align: left;
}

.projectCreateLangsListItem {
  display: block;
}

.projectCreateLangsBtn {
  position: relative;
  width: 100%;
  height: 38px;
  padding-left: 22px;
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: transparent;
  appearance: none;
  transition: color .2s ease-out;

  &.active,
  &:hover {
    color: #116FFC;
  }

  &.active::after {
    content: "";
    display: inline-block;
    width: 3px;
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    background-color: #116FFC;
  }
}

.projectCreateInputError {
  border-color: $red-color !important;
}

.projectImportWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.projectImportDropZone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 2px dashed #D8D8D8;
  box-sizing: border-box;
  border-radius: 10px;

  &:focus {
    outline: none;
  }
}

.projectImportDragText {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: $gray-color;
}

.projectImportControls {
  text-align: right;
  margin-top: 16px;
}

.projectImportDragAgain {
  color: $primary-text-color;
  margin-bottom: 8px;
}

.projectImportDragAgainLink  {
  font-size: 14px;
  line-height: 16px;
  color: $blue-color !important;
  cursor: pointer;
}

.projectEnterNameForm {
  flex: 1;
  padding: 38px 31px 0;
  text-align: left;
}

.projectEnterNameLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 17px;
}

.projectEnterNameControls {
  padding: 17px;
  border-top: solid 1px #DDDDDD;
  text-align: right;
}

.projectImportDragError {
  color: $red-color;
  margin-bottom: 8px;
}
